@import url('../../styles/colors.css');

.button {
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    text-decoration: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
}
  
  .large {
    font-size: 16px;
    padding: 12px 16px;
    height: 48px;
  }
  
  .small {
    font-size: 15px;
    padding: 12px 16px;
    height: 42px;
  }
  
  .confirm {
    background-color: var(--dark1-color);
    color: var(--white-color);
  }

  .decline {
    background-color: var(--error-color);
    color: var(--white-color);
  }

  .start {
    background-color: var(--dark2-color);
    color: var(--white-color);
  }

  .back {
    background-color: var(--white-color);
    color: var(--dark3-color);
    border: 1px solid var(--gray2-color);
  }

  .confirm:hover {
    background-color: #33323A; /* Более темный оттенок основного цвета */
  }

/* Стиль при нажатии */
  .confirm:active {
    background-color: #2C2B32; /* Еще более темный оттенок */
    transform: translateY(1px); /* Легкий эффект нажатия */
  }

  .decline:hover {
    background-color: #C12323; /* Более темный оттенок основного цвета */
  }

/* Стиль при нажатии */
  .decline:active {
    background-color: #A51E1E; /* Еще более темный оттенок */
    transform: translateY(1px); /* Легкий эффект нажатия */
  }

  .start:hover {
    background-color: #242328; /* Более темный оттенок основного цвета */
  }

/* Стиль при нажатии */
  .start:active {
    background-color: #1F1E22; /* Еще более темный оттенок */
    transform: translateY(1px); /* Легкий эффект нажатия */
  }

  .back:hover {
    background-color: #F8F8F8; /* Более темный оттенок основного цвета */
  }

/* Стиль при нажатии */
  .back:active {
    background-color: #F0F0F0; /* Еще более темный оттенок */
    transform: translateY(1px); /* Легкий эффект нажатия */
  }