@import url('../../styles/colors.css');

.trustly-button {
    display: inline-block;
    text-align: center;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background-color: var(--white-color);
    color: var(--dark1-color);
    font-size: 14px;
    padding: 14px 18px;
    border: 1px solid #DDDCDE;
    box-sizing: border-box;
}

  .trustly-button:hover {
    background-color: #F8F8F8; /* Более темный оттенок основного цвета */
  }

/* Стиль при нажатии */
  .trustly-button:active {
    background-color: #F0F0F0; /* Еще более темный оттенок */
    transform: translateY(1px); /* Легкий эффект нажатия */
  }