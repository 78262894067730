@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFPRODISPLAYBLACKITALIC.OTF') format('opentype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFPRODISPLAYBOLD.OTF') format('opentype');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFPRODISPLAYHEAVYITALIC.OTF') format('opentype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFPRODISPLAYLIGHTITALIC.OTF') format('opentype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFPRODISPLAYMEDIUM.OTF') format('opentype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFPRODISPLAYREGULAR.OTF') format('opentype');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFPRODISPLAYSEMIBOLDITALIC.OTF') format('opentype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFPRODISPLAYTHINITALIC.OTF') format('opentype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'SF Pro Display';
    src: url('../fonts/SFPRODISPLAYULTRALIGHTITALIC.OTF') format('opentype');
    font-weight: 200;
    font-style: italic;
}