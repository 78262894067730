:root {
    --white-color: #FFFFFF;

    --gray0-color: #F6F6F6;
    --gray1-color: #F3F3F3;
    --gray2-color: #E4E4E4;
    --gray3-color: #D2D2D3;
    --gray4-color: #A4A4A7;

    --darkgray1-color: #76767B;
    --darkgray2-color: #59575F;

    --dark1-color: #3A3942;
    --dark2-color: #29282D;
    --dark3-color: #161616;

    --error-color: #DC2727;
    
    --light-blue-color: #D3DBE7;
    --light-yellow-color: #FEFBE7;
  }