body {
    font-family: 'SF Pro Display', sans-serif;
    font-weight: 400;
}

button {
    font-family: 'SF Pro Display', sans-serif;
    font-weight: 500;
}

input, label, div {
    font-family: 'SF Pro Display', sans-serif;
    font-weight: 300;
}

span {
    font-family: 'SF Pro Display', sans-serif;
    font-weight: 500;
}

h1 {
    font-family: 'SF Pro Display', sans-serif;
    font-weight: 700;
}

em {
    font-family: 'SF Pro Display', sans-serif;
    font-style: italic;
}

@keyframes move {
    0% {
      opacity: 0.15;
      transform: translateX(100%);
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.15;
      transform: translateX(100%);
    }
  }

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

.session-store {
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 5px;
  font-size: 12px;
  max-height: 100vh;
  overflow-y: auto;
}

.session-store pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}