@import url('../styles/colors.css');

::placeholder {
    color: var(--darkgray1-color) !important;
    font-size: 15px;
  }
  
  ::-webkit-input-placeholder {
    color: var(--darkgray1-color) !important;
    font-size: 15px;
  }
  
  ::-moz-placeholder {
    color: var(--darkgray1-color) !important;
    font-size: 15px;
  }
  
  :-ms-input-placeholder {
    color: var(--darkgray1-color) !important;
    font-size: 15px;
  }
  
  :-moz-placeholder {
    color: var(--darkgray1-color) !important;
    font-size: 15px;
  }

.custom-label {
    margin-bottom: 4px;
    color: var(--darkgray2-color);
    font-weight: 400;
    font-size: 14px;
}

.card-logos {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 5;
    display: flex;
    align-items: center;
}

.static-card-logos {
    display: flex;
    align-items: center;
}

.static-card-logo {
    height: 20px;
    width: 32px;
    margin-right: 2px;
}

.dynamic-card-logo {
    position: relative;
    height: 20px;
    width: 32px;
    margin-left: 2px;
}

.card-logo {
    position: absolute;
    right: 0;
    top: 0;
    height: 20px;
    width: 32px;
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    pointer-events: none;
}

.card-logo.active {
    opacity: 1;
}

.detected-card-logo {
    height: 20px;
    width: 32px;
}

.cvc {
    position: absolute;
    right: 10px;
    top: 13%;
    z-index: 5;
}

.upper-input {
    border-radius: 0.375rem 0.375rem 0 0 !important;
}

.left-bottom-input {
    border-right: 0px;
    border-top: 0px;
    border-radius: 0 0 0 0.375rem;
}

.right-bottom-input {
    border-top: 0px;
    border-radius: 0px 0px 0.375rem 0px !important;
}

.bottomer-input {
    border-radius: 0px 0px 0.375rem 0.375rem !important;
    border-top: 0px;
}

.card-logo {
    padding-right: 2px;
}

.error-icon {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 11;
    color: var(--error-color);
    height: 20px;
    width: 20px;
}

.error-message {
    color: var(--error-color);
    font-size: 14px;
    margin-top: 6px;
    font-weight: 400;
}

.form-control {
    box-shadow: 0 0 0 1px #e0e0e0,0 2px 4px 0 rgba(0,0,0,0.07),0 1px 1.5px 0 rgba(0,0,0,0.05);
    border: 0px;
}

.form-select {
    box-shadow: 0 0 0 1px #e0e0e0,0 2px 4px 0 rgba(0,0,0,0.07),0 1px 1.5px 0 rgba(0,0,0,0.05);
    border: 0px;
}

.form-control.is-invalid {
    box-shadow: 0 0 0 1px #ef9896,0 2px 4px 0 rgba(0,0,0,0.07),0 1px 1.5px 0 rgba(0,0,0,0.05);
    border: 0px;
}

.position-relative {
    position: relative;
}

.required-error {
    position: absolute;
    right: 2px;
    top: 4px;
    color: var(--error-color);
    font-size: 12px;
    font-weight: 400;
}

.card-required-error {
    top: -22px;
}

.card-input-wrapper {
    margin-bottom: 1rem;
}