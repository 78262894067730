@import url('../../styles/colors.css');

.payment-button {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 4px;
    text-decoration: none;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    width: 100%;
    position: relative;
    background-color: var(--dark1-color);
    color: var(--white-color);
}

.payment-button::before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 200%;
    height: 100%;
    background: linear-gradient(
        to right,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.1) 50%,
        rgba(255, 255, 255, 0) 100%
    );
    animation: shimmer 3s infinite;
}

.payment-button:hover::before {
    animation: none;
}

.payment-button:disabled::before {
    animation: none;
}

@keyframes shimmer {
    0% {
        transform: translateX(-100%);
    }
    100% {
        transform: translateX(100%);
    }
}

.large {
    font-size: 16px;
    padding: 10px 16px;
    height: 42px;
    border-radius: 6px;
}
  
.small {
    font-size: 15px;
    padding: 12px 16px;
    height: 42px;
}

.payment-button-content {
    flex-grow: 1;
}

.payment-button-icon {
    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;
}

.payment-loading-icon {
    animation: spin 1s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}


.payment-button:hover {
    background-color: #33323A; /* Более темный оттенок основного цвета */
  }

.payment-button:active {
    background-color: #2C2B32; /* Еще более темный оттенок */
    transform: translateY(1px); /* Легкий эффект нажатия */
}

.payment-button:disabled {
    cursor: not-allowed;
    color: rgba(255, 255, 255, 0.6);
    animation: none !important;
}

.loading::before {
    animation: none !important;
}